import React, {useState, useEffect, useContext, useCallback} from 'react';
import {v4 as uuid} from "uuid";
import {useEnvironment} from "../components/EnvironmentProvider";
import {CartContext} from "../components/CartContext";
import {addMonths, format} from 'date-fns';

const PaymentForm = ({patientInfo, paymentInfo, setPaymentInfo, handlePrevious, handleNext}) => {

    const env = useEnvironment();
    const idempotencyKey = uuid();
    const applicationId = env.squareAppId;
    const locationId = env.squareLocationId;
    const apiEndpoint = env.apiEndpoint;

    const {cartItems} = useContext(CartContext);

    const [card, setCard] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [verifyTerms, setVerifyTerms] = useState(false);
    const [validated, setValidated] = useState(false);

    const [copyFromPatientInfo, setCopyFromPatientInfo] = useState(false);

    useEffect(() => {
        let isMounted = true;
        let cardInstance = null;

        const loadSquare = async () => {
            try {
                const payments = window.Square.payments(applicationId, locationId);
                const card = await payments.card();
                cardInstance = card;
                if (isMounted) {
                    await card.attach('#card-container');
                    setCard(card);
                }
            } catch (e) {
                console.error('Square initialization failed', e);
            }
        };

        loadSquare();

        return () => {
            isMounted = false;
            if (cardInstance) {
                cardInstance.destroy();
            }
        };
    }, [applicationId, locationId]);

    useEffect(() => {
        if (copyFromPatientInfo) {
            setPaymentInfo({
                firstName: patientInfo.firstName || '',
                lastName: patientInfo.lastName || '',
                address1: patientInfo.address1 || '',
                address2: patientInfo.address2 || '',
                city: patientInfo.city || '',
                state: patientInfo.state || '',
                zipCode: patientInfo.zipCode || '',
            });
        } else {
            setPaymentInfo(prevData => ({
                ...prevData,
                firstName: '',
                lastName: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                zipCode: '',
            }));
        }
    }, [copyFromPatientInfo, patientInfo, setPaymentInfo]);

    const handleChange = useCallback((e) => {
        const {name, value, type, checked} = e.target;
        setPaymentInfo(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
        if (name === 'verifyTerms') {
            setVerifyTerms(checked);
        }
    }, [setPaymentInfo]);

    const handleCheckboxChange = useCallback((e) => {
        setCopyFromPatientInfo(e.target.checked);
    }, []);

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);

            // Find the first invalid input
            const firstInvalidInput = form.querySelector(':invalid');
            if (firstInvalidInput) {
                firstInvalidInput.scrollIntoView({behavior: 'smooth', block: 'center'});
            }
            return;
        }
        if (!card) {
            return;
        }
        setErrorMessage(null);
        setIsLoading(true);

        try {
            const result = await card.tokenize();
            if (result.status === 'OK') {
                const submissionData = {
                    memberInfo: patientInfo,
                    paymentInfo: {
                        ...paymentInfo,
                        paymentToken: result.token
                    },
                    package: {
                        id: cartItems[0].id,
                        name: cartItems[0].name,
                        description: cartItems[0].description,
                        category: cartItems[0].category,
                        bundle: cartItems[0].bundle,
                        verifyTerms: verifyTerms,
                        disclaimers: cartItems[0].disclaimers
                    },
                    paymentOption: cartItems[0].paymentOption,
                    idempotencyKey,
                };

                const response = await fetch(`${apiEndpoint}/registration`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    body: JSON.stringify(submissionData),
                    mode: 'cors',
                });

                if (!response.ok) {
                    const errorResponse = await response.json();
                    const msg = errorResponse.message || 'Failed to submit the form';
                    throw new Error(msg);
                }

                setErrorMessage(null);
                handleNext();

            } else {
                throw new Error(result.errors ? result.errors[0].message : 'Tokenization failed');
            }
        } catch (e) {
            setErrorMessage(e.message);
        } finally {
            setIsLoading(false);
        }
    }, [card, paymentInfo, patientInfo, cartItems, handleNext, idempotencyKey]);

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return (
        <form noValidate onSubmit={handleSubmit} className={`row g-3 mt-0 ${validated ? 'was-validated' : ''}`}>
            {/* Selected Package */}
            <div className="row mt-5">
                <div className="col-12">
                    <h4>Your Selection(s)</h4>
                </div>
                <div className="col-12">
                    {cartItems[0].paymentOption.type === 'recurring' ? (
                        <div className="row bg-light border rounded p-3">
                            <div className="col-12 col-md-10">
                                <h5>{cartItems[0].name} ({cartItems[0].paymentOption.name})</h5>
                                <p>{cartItems[0].paymentOption.description}</p>
                                <p className="mb-1"><small>Next Payment Due:</small></p>
                                <h5>{format(addMonths(new Date(), 1), 'MMMM d, yyyy')}</h5>
                                {cartItems[0].paymentOption.disclaimers && cartItems[0].paymentOption.disclaimers.length > 0 && (
                                    <div>
                                        {cartItems[0].paymentOption.disclaimers.map((disclaimer, disclaimerIndex) => (
                                            <p key={`payment_option_disclaimer_${disclaimerIndex}`}
                                               className="small text-danger">{disclaimer.description}</p>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="col-12 col-md-2 text-md-end">
                                <p className="mb-1"><small>Due Today</small></p>
                                <h5>{formatter.format(cartItems[0].paymentOption.periodicBillingAmount)}</h5>
                            </div>
                        </div>
                    ) : cartItems[0].paymentOption.type === 'one-time' ? (
                        <div className="row bg-light border rounded p-3">
                            <div className="col-12 col-md-10">
                                <h5>{cartItems[0].name} ({cartItems[0].paymentOption.name})</h5>
                                <p>{cartItems[0].paymentOption.description}</p>
                                {cartItems[0].paymentOption.disclaimers && cartItems[0].paymentOption.disclaimers.length > 0 && (
                                    <div>
                                        {cartItems[0].paymentOption.disclaimers.map((disclaimer, disclaimerIndex) => (
                                            <p key={`payment_option_disclaimer_${disclaimerIndex}`}
                                               className="small text-danger">{disclaimer.description}</p>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="col-12 col-md-2 text-md-end">
                                <p className="mb-1"><small>Due Today</small></p>
                                <h5>{formatter.format(cartItems[0].paymentOption.totalAmount)}</h5>
                            </div>
                        </div>
                    ) : (
                        // Default case or fallback
                        <div className="row bg-light border rounded p-3">
                            <div className="col-12 col-md-10">
                                <h5>{cartItems[0].name} ({cartItems[0].paymentOption.name})</h5>
                                <p>{cartItems[0].paymentOption.description}</p>
                                <p className="mb-1"><small>Next Payment Due:</small></p>
                                <h5>
                                    <span>{formatter.format(cartItems[0].paymentOption.downPayment.installments.paymentSchedule[0].amount)} on </span>
                                    {format(cartItems[0].paymentOption.downPayment.installments.paymentSchedule[0].dueDate, 'MMMM d, yyyy')}
                                </h5>
                                {cartItems[0].paymentOption.disclaimers && cartItems[0].paymentOption.disclaimers.length > 0 && (
                                    <div>
                                        {cartItems[0].paymentOption.disclaimers.map((disclaimer, disclaimerIndex) => (
                                            <p key={`payment_option_disclaimer_${disclaimerIndex}`}
                                               className="small text-danger my-1">{disclaimer.description}</p>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="col-12 col-md-2 text-md-end">
                                <p className="mb-1"><small>Due Today</small></p>
                                <h5>{formatter.format(cartItems[0].paymentOption.amountDue)}</h5>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* Billing Information */}
            <div className="row">
                <div className="col-12">
                    <h4 className="mt-5">Billing Address</h4>
                    <p className="mb-0">
                        This is the address where you receive your credit card billing statement.
                        Ensure it matches your card company's records to avoid delays.
                    </p>
                </div>
            </div>

            {/* Checkbox to copy from patientInfo */}
            <div className="col-12">
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="copyFromPatientInfo"
                        checked={copyFromPatientInfo}
                        onChange={handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="copyFromPatientInfo">
                        Copy Patient Information from Previous Page
                    </label>
                </div>
            </div>

            {/* Form Fields */}
            <div className="col-sm-12 col-md-6">
                <label htmlFor="firstName" className="form-label">First Name *</label>
                <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    value={paymentInfo.firstName}
                    onChange={handleChange}
                    required
                />
                <div className="invalid-feedback">First Name is required</div>
            </div>
            <div className="col-sm-12 col-md-6">
                <label htmlFor="lastName" className="form-label">Last Name *</label>
                <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    name="lastName"
                    value={paymentInfo.lastName}
                    onChange={handleChange}
                    required
                />
                <div className="invalid-feedback">Last Name is required</div>
            </div>
            <div className="col-12">
                <label htmlFor="address1" className="form-label">Address *</label>
                <input
                    type="text"
                    className="form-control"
                    id="address1"
                    name="address1"
                    value={paymentInfo.address1}
                    onChange={handleChange}
                    required
                />
                <div className="invalid-feedback">Address is required</div>
            </div>
            <div className="col-12">
                <input
                    type="text"
                    className="form-control"
                    id="address2"
                    name="address2"
                    value={paymentInfo.address2}
                    onChange={handleChange}
                />
            </div>
            <div className="col-sm-12 col-md-6">
                <label htmlFor="city" className="form-label">City *</label>
                <input
                    type="text"
                    className="form-control"
                    id="city"
                    name="city"
                    value={paymentInfo.city}
                    onChange={handleChange}
                    required
                />
                <div className="invalid-feedback">City is required</div>
            </div>
            <div className="col-sm-6 col-md-3">
                <label htmlFor="state" className="form-label">State *</label>
                <select
                    id="state"
                    className="form-select"
                    name="state"
                    value={paymentInfo.state}
                    onChange={handleChange}
                    required
                >
                    <option value="">Choose...</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                </select>
                <div className="invalid-feedback">Please select a valid state</div>
            </div>
            <div className="col-sm-6 col-md-3">
                <label htmlFor="zipCode" className="form-label">Zip *</label>
                <input
                    type="text"
                    className="form-control"
                    id="zipCode"
                    name="zipCode"
                    value={paymentInfo.zipCode}
                    onChange={handleChange}
                    required
                />
                <div className="invalid-feedback">Zip code is required</div>
            </div>

            {/* Terms and Conditions */}
            <div className="col-12 text-start">
                <h4 className="mt-3 mb-0">Terms and Conditions</h4>
                <div className="collapse mt-2" id="collapseExample">
                    <div className="card card-body py=2"
                         dangerouslySetInnerHTML={{__html: env.termsConditionsHtml}}/>
                </div>

                <div className="form-check mt-2">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        name="verifyTerms"
                        checked={verifyTerms}
                        id="verifyTerms"
                        onChange={handleChange}
                        required
                    />
                    <label htmlFor="verifyTerms" className="form-check-label">
                        <span>I affirm that I have read and agree with the </span>
                        <a
                            data-bs-toggle="collapse"
                            href="#collapseExample"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                        >
                            Terms and Conditions.
                        </a>
                    </label>
                    <div className="invalid-feedback">
                        Please confirm you have read the Terms and Conditions
                    </div>
                </div>
            </div>

            {/* Payment Form */}
            <div className="row mt-3">
                <div className="col-12">
                    <h4>Payment Details</h4>
                </div>
                <div id="card-container" className="col-12"></div>
                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            </div>

            {/* Buttons */}
            <div className="col-12 mt-4 text-center">
                <button type="button" className="btn btn-secondary me-3" onClick={handlePrevious}>Previous</button>
                <button type="submit" className="btn btn-primary" disabled={isLoading}>
                    {isLoading ? 'Processing...' : 'Next'}
                </button>
            </div>
        </form>
    );
};

export default PaymentForm;
