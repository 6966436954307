import React, { createContext, useState, useContext } from 'react';
import {v4 as uuid} from "uuid";

// Create context
const FunnelContext = createContext();

// Provider component
export const FunnelProvider = ({ children }) => {

    const idempotencyKey = uuid();
    const initialFormData = {
        firstName: '',
        lastName: '',
        emailAddress: '',
        phoneNumber: '',
        idempotencyKey: idempotencyKey
    };

    const [formData, setFormData] = useState(initialFormData);

    const updateFormData = (newData) => {
        setFormData((prevData) => ({
            ...prevData,
            ...newData,
        }));
    };

    return (
        <FunnelContext.Provider value={{ formData, updateFormData }}>
            {children}
        </FunnelContext.Provider>
    );
};

// Custom hook to use the FormContext
export const useFormContext = () => {
    return useContext(FunnelContext);
};
