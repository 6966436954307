import React, { useEffect, useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { useFormContext } from './FunnelContext';
import {useEnvironment} from "../components/EnvironmentProvider";

function ContactInformation({ nextStep }) {

  const env = useEnvironment();
  const apiEndpoint = env.apiEndpoint;

  const { formData, updateFormData } = useFormContext();
  const [isLoading, setIsLoading] = useState(true);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(false);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateFormData({ [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      const firstInvalidInput = form.querySelector(':invalid');
      if (firstInvalidInput) {
        firstInvalidInput.scrollIntoView({behavior: 'smooth', block: 'center'});
      }
      return;
    }

    try {
      // Prevent the submit button from being pressed
      setIsLoading(true);

      const submissionData = {
        memberInfo: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          emailAddress: formData.emailAddress,
          phoneNumber: formData.phoneNumber
        },
        idempotencyKey: formData.idempotencyKey
      };

      const response = await fetch(`${apiEndpoint}/funnelservice`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(submissionData),
        mode: 'cors',
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        const msg = errorResponse.message || 'Failed to submit the form';
        setIsLoading(false);
        throw new Error(msg);
      }

      console.log('Form submitted:', formData);
      nextStep('SCHEDULE_DISCOVERY_CALL');

    } catch (e) {
      setIsLoading(false);
    }
  };

  return (
      <div className="container">
        <form noValidate onSubmit={handleSubmit} className={`row g-3 mt-0 ${validated ? 'was-validated' : ''}`}>

          {/* First Name */}
          <div className="col-12">
            <label htmlFor="firstName" className="form-label">First Name *</label>
            <input
                type="text"
                className="form-control"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
            />
            <div className="invalid-feedback">First Name is required</div>
          </div>

          {/* Last Name */}
          <div className="col-12">
            <label htmlFor="lastName" className="form-label">Last Name *</label>
            <input
                type="text"
                className="form-control"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
            />
            <div className="invalid-feedback">Last Name is required</div>
          </div>

          {/* Email Address */}
          <div className="col-12">
            <label htmlFor="emailAddress" className="form-label">Email Address *</label>
            <input
                type="email"
                className="form-control"
                id="emailAddress"
                name="emailAddress"
                value={formData.emailAddress}
                onChange={handleChange}
                required
            />
            <div className="invalid-feedback">Email Address is required</div>
          </div>

          {/* Phone Number */}
          <div className="col-12">
            <label htmlFor="phoneNumber" className="form-label">Phone Number *</label>
            <PatternFormat
                format="(###) ###-####"
                mask="_"
                className="form-control"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
            />
            <div className="invalid-feedback">Phone Number is required</div>
          </div>

          {/* Submit Button */}
          <div className="col-12 mt-4 text-center">
            <button type="submit" className="btn btn-primary" disabled={isLoading}>
              {isLoading ? 'Processing ...' : 'Next'}
            </button>
          </div>
        </form>
      </div>
  );
}

export default ContactInformation;
