import React, { useCallback, useState, useEffect } from 'react';
import "../css/Registration.css";
import ContactInformation from "./ContactInformation";
import ThankYouPage from "./ThankYou";
import { FunnelProvider } from './FunnelContext';
import CalendlyForm from './CalendlyForm';

function FunnelLayout() {

    const [step, setStep] = useState(1);

    // Scroll to top function
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Adds smooth scrolling animation
        });
        window.parent.postMessage({ type: 'scrollToTop' }, '*');
    };

    // Scroll to top when the component is first rendered
    useEffect(() => {
        scrollToTop();
    }, []);  // Empty dependency array means this runs only once on initial load


    const previousStep = useCallback(() => {
        setStep(prevStep => Math.max(prevStep - 1, 1));
        scrollToTop();
    }, []);

    const nextStep = useCallback((step) => {
        setStep(step);
        scrollToTop();
    }, []);

    const determineNextStep = useCallback((dropdownValue) => {
        console.log(dropdownValue)
        if (dropdownValue === 'SCHEDULE_DISCOVERY_CALL') {
            nextStep(2);
        } else {
            console.log("Invalid dropdown selection:", dropdownValue);
        }
    }, [nextStep]);

    return (
        <FunnelProvider>
            {/* Use a Bootstrap container-fluid for full-width layout */}
            <div className="container-fluid">
                <div className="row justify-content-center">
                    {/* Make this column take full width of the viewport */}
                    <div className="col-12">
                        <div className="row my-4">
                            <div className="col">
                                {step === 1 &&
                                    <ContactInformation nextStep={determineNextStep} />}
                                {step === 2 &&
                                    <CalendlyForm previousStep={previousStep} nextStep={nextStep} />}
                                {step === 4 && <ThankYouPage />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </FunnelProvider>
    );
}

export default FunnelLayout;
