import React, { useEffect } from 'react';
import { useFormContext } from './FunnelContext';

function ThankYouPage() {
    const { formData } = useFormContext();

    const emailMessage = formData?.emailAddress
        ? `${formData.emailAddress}`
        : 'your email';

    useEffect(() => {
        (function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
                n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = '2.0';
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
        })(
            window,
            document,
            'script',
            'https://connect.facebook.net/en_US/fbevents.js'
        );

        // Facebook Pixel initialization and event tracking
        if (window.fbq) {
            window.fbq('init', '369251114850690');
            window.fbq('track', 'PageView');
            window.fbq('track', 'CompleteRegistration');
        }
    }, []);


    return (
        <div className="container">
            <div className="text-center">
                {/* THANK YOU HEADER */}
                <h1 className="pt-5 display-5 fw-bold text-center">Thank You!</h1>
                <div className="col-lg-8 mx-auto">
                    {/* THANK YOU BODY */}
                    <p className="lead mb-4">
                        Your information was received. Thank you for your interest!
                    </p>
                </div>
            </div>

            {/* NoScript Facebook Pixel Fallback */}
            <noscript>
                <img
                    height="1"
                    width="1"
                    style={{ display: 'none' }}
                    src="https://www.facebook.com/tr?id=369251114850690&ev=CompleteRegistration&noscript=1"
                    alt="Facebook Pixel"
                />
            </noscript>
        </div>
    );
}

export default ThankYouPage;
