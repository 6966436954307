import React, { useEffect } from 'react';
import { useEnvironment } from '../components/EnvironmentProvider';
import { useFormContext } from './InterestFormContext';
import { useCalendlyEventListener, InlineWidget } from "react-calendly";
import '../css/BusinessConsultingForm.css';

function BusinessConsultingForm({ nextStep }) {

    const env = useEnvironment();
    const { formData } = useFormContext();
    const discoveryCallCalendarUrl = env.interestFormCalendarUrl;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const prefill = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.emailAddress,
        q1: formData.phoneNumber
    };

    const utm = {
        utmSource: 'INTEREST_FORM',
        utmCampaign: formData.idempotencyKey
    };

    useCalendlyEventListener({
        onEventScheduled: async (event) => {
            nextStep(4);
        },
    });

    return (
        <div>
            <InlineWidget url={discoveryCallCalendarUrl} prefill={prefill} utm={utm} />
        </div>
    );
}

export default BusinessConsultingForm;
