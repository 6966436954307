import React, {useEffect} from 'react';
import {useEnvironment} from '../components/EnvironmentProvider';
import {useFormContext} from './FunnelContext';
import {useCalendlyEventListener, InlineWidget} from "react-calendly";
import '../css/BusinessConsultingForm.css';

function BusinessConsultingForm({nextStep}) {

    const env = useEnvironment();
    const {formData} = useFormContext();
    const discoveryCallCalendarUrl = env.discoveryCallCalendarUrl;

    useEffect(() => {
        window.scrollTo(0, 0);

        // Initialize Facebook Pixel
        (function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
                n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = true;
            n.version = '2.0';
            n.queue = [];
            t = b.createElement(e);
            t.async = true;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
        })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

        // Track PageView and Schedule events
        window.fbq('init', '369251114850690');
        window.fbq('track', 'PageView');
        window.fbq('track', 'Schedule');

        // Add NoScript Fallback
        const noscript = document.createElement("noscript");
        noscript.innerHTML = `
            <img height="1" width="1" style="display:none" 
            src="https://www.facebook.com/tr?id=369251114850690&ev=Schedule&noscript=1"/>
        `;
        document.body.appendChild(noscript);

    }, []);

    const prefill = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.emailAddress,
        q1: formData.phoneNumber
    };

    const utm = {
        utmSource: 'FUNNEL',
        utmCampaign: formData.idempotencyKey
    };

    useCalendlyEventListener({
        onEventScheduled: async (event) => {
            nextStep(4);
        },
    });

    return (
        <div>
            {/* Facebook Pixel Thank You Header */}
            <noscript>
                <img height="1" width="1" style={{display: "none"}}
                     src="https://www.facebook.com/tr?id=369251114850690&ev=Schedule&noscript=1"
                     alt="Facebook Pixel"/>
            </noscript>

            <InlineWidget url={discoveryCallCalendarUrl} prefill={prefill} utm={utm}/>
        </div>
    );
}

export default BusinessConsultingForm;
